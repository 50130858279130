import { useState, useCallback, useEffect } from "react";
import moment from "moment-timezone";

export function useBooking({
  organizationTimeZone,
  organizationWorkingHours,
  organizationCustomWorkingHours = [],
  submitBooking,
  message,
  chatAppointment,
}) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");
  const [availableTimes, setAvailableTimes] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (chatAppointment?.date) {
      const parsedDate = moment(chatAppointment.date);

      if (parsedDate.isValid()) {
        const formattedDate = parsedDate.format("MMMM DD, YYYY");
        handleDateChange(formattedDate);
      }

      if (chatAppointment.time) {
        const parsedTime = moment(chatAppointment.time, "HH:mm");

        if (parsedTime.isValid()) {
          const formattedTime = parsedTime.format("hh:mm A");
          setSelectedTime(formattedTime);
        }
      }
    }
  }, [chatAppointment]);

  const isDateOpen = useCallback(
    (date) => {
      const dateString = moment(date).format("YYYY-MM-DD");

      const customHours = organizationCustomWorkingHours.find(
        (customDate) => customDate.date === dateString
      );

      if (customHours) {
        return customHours.isOpen;
      }

      const dayName = moment(date).tz(organizationTimeZone).format("dddd");
      const dayWorkingHours = organizationWorkingHours[dayName];

      return dayWorkingHours?.isOpen || false;
    },
    [
      organizationTimeZone,
      organizationWorkingHours,
      organizationCustomWorkingHours,
    ]
  );

  const generateAvailableTimes = useCallback(
    (date) => {
      const dateString = moment(date).format("YYYY-MM-DD");
      let workingHours = null;

      const customHours = organizationCustomWorkingHours.find(
        (customDate) => customDate.date === dateString
      );

      if (customHours && customHours.isOpen) {
        workingHours = customHours;
      } else {
        const dayName = moment(date).tz(organizationTimeZone).format("dddd");
        const dayWorkingHours = organizationWorkingHours[dayName];

        if (dayWorkingHours && dayWorkingHours.isOpen) {
          workingHours = dayWorkingHours;
        }
      }

      if (!workingHours) {
        return [];
      }

      const times = [];
      const startTime = moment
        .tz(
          `${dateString} ${workingHours.from}`,
          "YYYY-MM-DD h:mm A",
          organizationTimeZone
        )
        .startOf("minute");

      const endTime = moment
        .tz(
          `${dateString} ${workingHours.to}`,
          "YYYY-MM-DD h:mm A",
          organizationTimeZone
        )
        .startOf("minute");

      const nowInOrgTz = moment.tz(organizationTimeZone).startOf("minute");

      // Align start time to the next 30 min interval if it's off
      if (startTime.minutes() % 30 !== 0) {
        startTime.minutes(Math.ceil(startTime.minutes() / 30) * 30).seconds(0);
      }

      const currentTime = startTime.clone();

      while (currentTime.isBefore(endTime)) {
        if (moment(date).isSame(nowInOrgTz, "day")) {
          if (currentTime.isSameOrAfter(nowInOrgTz)) {
            times.push(currentTime.format("hh:mm A"));
          }
        } else {
          times.push(currentTime.format("hh:mm A"));
        }
        currentTime.add(30, "minutes");
      }

      return times;
    },
    [
      organizationTimeZone,
      organizationWorkingHours,
      organizationCustomWorkingHours,
    ]
  );

  const handleDateChange = useCallback(
    (date) => {
      setSelectedDate(date);
      setSelectedTime("");
      const times = generateAvailableTimes(date);
      setAvailableTimes(times);
    },
    [generateAvailableTimes]
  );

  const handleTimeChange = useCallback((event) => {
    setSelectedTime(event.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    setFormSubmitted(true);
    submitBooking({
      date: selectedDate,
      time: selectedTime,
    });
  }, [selectedDate, selectedTime, submitBooking]);

  const currentDay = moment().tz(organizationTimeZone).toDate();

  return {
    selectedDate,
    selectedTime,
    availableTimes,
    formSubmitted,
    currentDay,
    isDateOpen,
    handleDateChange,
    handleTimeChange,
    handleSubmit,
  };
}
