import React from "react";
import styled from "styled-components";
import { strings } from "../../utils/localization";

import { ReactComponent as SuccessIcon } from "../../assets/images/common/successIcon.svg";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 10px;
`;

const Title = styled.span`
  font-family: "Roboto";
  font-size: 19px;
  color: #2d2d66;
`;

const Label = styled.span`
  font-family: "Roboto";
  font-size: 13px;
  color: #2d2d66;
  font-weight: bold;
`;

const ThankYou = ({ theme }) => {
  const hwValue = theme === "default" ? 76 : 51;

  return (
    <Wrapper>
      <SuccessIcon height={hwValue} width={hwValue} />
      <div style={{ marginLeft: 10 }}>
        <Title>{strings.THANK_YOU}</Title>
        <br />
        <Label>{strings.WILL_CONTACT}</Label>
      </div>
    </Wrapper>
  );
};

export default ThankYou;
