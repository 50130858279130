import { getCachedData, setCacheData } from "../utils/cookiesHelper";
import Cookies from "js-cookie";

const BASE_URL = `https://${process.env.REACT_APP_DOMAIN}/api/v1`;
const DEFAULT_CACHE_EXPIRATION = 10 * 60; // 10 minutes

const getUrlWithParams = ({ url, params }) => {
  let urlWithParams = url;
  if (params && Object.keys(params).length > 0) {
    const queryParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (typeof value === "object") {
          queryParams.append(key, JSON.stringify(value));
        } else {
          queryParams.append(key, value);
        }
      }
    });
    const queryString = queryParams.toString();
    if (queryString) {
      urlWithParams = `${url}${url.includes("?") ? "&" : "?"}${queryString}`;
    }
  }
  return urlWithParams;
};

const fetchApi = async (endpoint, method, options = {}) => {
  const {
    body,
    headers = {},
    cache = true,
    cacheExpiration = DEFAULT_CACHE_EXPIRATION,
    cacheKey,
    params,
    cancelToken,
  } = options;

  const url = `${BASE_URL}${
    endpoint.startsWith("/") ? endpoint : `/${endpoint}`
  }`;
  const urlWithParams = getUrlWithParams({ url, params });
  const actualCacheKey = cacheKey || `api_cache_${urlWithParams}`;

  if (method === "GET" && cache) {
    const cachedData = getCachedData(actualCacheKey);
    if (cachedData) {
      return { success: true, data: cachedData };
    }
  }

  const requestOptions = {
    method,
    headers: { "Content-Type": "application/json", ...headers },
    ...(cancelToken && { signal: cancelToken.signal }),
  };

  if (body && method !== "GET") {
    requestOptions.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(urlWithParams, requestOptions);
    const data = await response.json();
    if (!response.ok) {
      return { success: false, error: data.message || response.statusText };
    }
    if (method === "GET" && cache) {
      setCacheData(actualCacheKey, data, cacheExpiration);
    }
    return { success: true, data };
  } catch (error) {
    console.error(`API request failed: ${error}`);
    return { success: false, error: error.message || "Network error" };
  }
};

const apiGet = (endpoint, options = {}) => {
  return fetchApi(endpoint, "GET", options);
};

const apiPost = (endpoint, body, options = {}) => {
  return fetchApi(endpoint, "POST", { ...options, body });
};

const apiPut = (endpoint, body, options = {}) => {
  return fetchApi(endpoint, "PUT", { ...options, body });
};

const apiPatch = (endpoint, body, options = {}) => {
  return fetchApi(endpoint, "PATCH", { ...options, body });
};

const apiDelete = (endpoint, options = {}) => {
  return fetchApi(endpoint, "DELETE", options);
};

const clearApiCache = () => {
  const cookiesArray = document.cookie.split(";");
  cookiesArray.forEach((cookie) => {
    const cookieName = cookie.split("=")[0].trim();
    if (cookieName.startsWith("api_cache_")) {
      Cookies.remove(cookieName, { path: "/" });
    }
  });
};

const clearCacheForEndpoint = (endpoint) => {
  const url = `${BASE_URL}${
    endpoint.startsWith("/") ? endpoint : `/${endpoint}`
  }`;
  const cacheKey = `api_cache_${url}`;
  Cookies.remove(cacheKey, { path: "/" });
};

export {
  apiGet,
  apiPost,
  apiPatch,
  apiPut,
  apiDelete,
  clearApiCache,
  clearCacheForEndpoint,
};
