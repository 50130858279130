import { apiGet } from "../../api/apiClient";

const urlCoupon = "/coupons/location";

class CouponMainActions {
  static getCoupon = async (locationId) => {
    return await apiGet(`${urlCoupon}/${locationId}`);
  };
}

export default CouponMainActions;
