import { apiPost, apiGet } from "../../../api/apiClient";

class AiChatApi {
  static sendMessage = ({ locationId, param, cancelToken }) => {
    return apiPost(`superhuman-chat/${locationId}/messages`, param, {
      headers: {
        ...(cancelToken && { cancelToken }),
      },
    });
  };

  static updateContact = ({ locationId, param, cancelToken }) => {
    return apiPost(`superhuman-chat/${locationId}/contact`, param, {
      headers: {
        ...(cancelToken && { cancelToken }),
      },
    });
  };

  static checkMessageStatus = ({ locationId, idempotencyKey, cancelToken }) => {
    return apiGet(`superhuman-chat/${locationId}/status/${idempotencyKey}`, {
      headers: {
        ...(cancelToken && { cancelToken }),
      },
    });
  };
}

export default AiChatApi;
