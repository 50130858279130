import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ChatHeader from "../Chat/Form/ChatHeader";
import MessagingInput from "./MessagingInput";
import MessagesList from "./MessagesList";
import SimpleLeadForm from "../Chat/Form/SimpleLeadForm";
import { useChatContext } from "../../contexts/ChatContext";
import LeadAppointmentForm from "../Chat/Form/LeadAppointmentForm";
import { strings } from "../../../utils/localization";
import ErrorAlert from "./ErrorAlert";
import useWiz from "../Chat/Wiz/useWiz";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MessagingApp = ({
  chatData,
  firstAndLastNameSeparately,
  firstName,
  lastName,
  setFirstName,
  setLastName,
  goBack,
  selectedQuestion,
  submit,
  viewIndex,
  img,
  second_message,
  organization_name,
  typingThree,
  uixFormDescription,
  answer,
  dateQuestion,
  typingTwo,
  name,
  setName,
  phoneNumber,
  setPhoneNumber,
  organizationTimeZone,
  organizationWorkingHours,
  organizationCustomWorkingHours,
  currentUrl,
  language,
  turnstileStatus,
  runTurnstileReset,
}) => {
  const { secondaryColor, color, _token, image_url } = chatData;
  const [text, setText] = useState("");
  const {
    messages,
    sendMessage,
    isSending,
    clearChat,
    triggerUrlRedirection,
    updateContact,
    readOnly,
    setIsInChatView,
    cancelApiRequest,
    checkInactiveConversation,
  } = useChatContext();
  const [error, setError] = useState(null);
  const { activeLocation, activeLocationId } = useWiz();
  const [initialMessageSent, setInitialMessageSent] = useState(false);

  useEffect(() => {
    setIsInChatView(true);
    toggleBubbleAnimation(true);
    return () => {
      toggleBubbleAnimation(false);
      setIsInChatView(false);
      cancelApiRequest();
    };
  }, []);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 6000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  useEffect(() => {
    if (turnstileStatus === "solved" && !initialMessageSent) {
      setInitialMessageSent(true);
      sendInitialMessage();
    }
  }, [turnstileStatus, messages]);

  const handleClearChat = () => {
    clearChat();
    goBack();
    cancelApiRequest();
    localStorage.setItem(
      "chatClosed",
      JSON.stringify({ closed: true, timestamp: Date.now() })
    );
  };

  useEffect(() => {
    const storageListener = (event) => {
      if (event.key === "chatClosed" && event.newValue) {
        const data = JSON.parse(event.newValue);
        if (data.closed) {
          handleClearChat();
        }
      }
    };

    window.addEventListener("storage", storageListener);

    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, []);

  useEffect(() => {
    if (messages.length > 0 || isSending) {
      scrollToBottom();
    }
  }, [messages, isSending]);

  const handleInactiveInterval = () => {
    if (readOnly) return;
    const result = checkInactiveConversation();

    if (result === "CLEAR") {
      handleClearChat();
    }
  };

  useEffect(() => {
    handleInactiveInterval();
    const interval = setInterval(handleInactiveInterval, 10000);
    return () => clearInterval(interval);
  }, [checkInactiveConversation]);

  const getCustomLocationId = () => {
    return activeLocationId || activeLocation?.locationId;
  };

  const scrollToBottom = () => {
    if (readOnly) return;
    const messagesContainer = document.getElementById("messages-container");
    if (messagesContainer) {
      messagesContainer.scrollTo({
        top: messagesContainer.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const toggleBubbleAnimation = (display) => {
    return; // temp
    if (display) {
      window.top.postMessage("MATADOR_AI_START_REPLYING", "*");
    } else {
      window.top.postMessage("MATADOR_AI_STOP_REPLYING", "*");
    }
  };

  const handleError = (message = "Something went wrong") => {
    setError(message);
    scrollToBottom();
  };

  const sendTextMessage = async ({ text }) => {
    try {
      runTurnstileReset();
      await sendMessage({
        text,
        url: currentUrl,
        customLocationId: getCustomLocationId(),
        language,
        setupLocationId: chatData?._location_id,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const sendInitialMessage = () => {
    sendTextMessage({
      text: selectedQuestion,
    });
  };

  const handleSubmitLeadForm = async ({ messageId }) => {
    runTurnstileReset();
    try {
      await updateContact({
        firstName: firstName || name,
        lastName,
        phoneNumber,
        customLocationId: getCustomLocationId(),
        language,
        messageId,
        setupLocationId: chatData?._location_id,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const getVehicleDetails = (vehicle) => {
    const {
      data: { stockNumber, vin, title },
    } = vehicle || {};
    let vehicleDetails = title;
    if (stockNumber) {
      vehicleDetails += ` - Stock ${stockNumber}`;
    }
    if (vin) {
      vehicleDetails += ` - VIN: ${vin}`;
    }
    return vehicleDetails;
  };

  const redirectToVdp = (vehicle) => {
    if (vehicle?.data?.vdpUrl) {
      triggerUrlRedirection({ url: vehicle?.data?.vdpUrl });
    }
  };

  const imInterested = (vehicle) => {
    const vehicleDetails = getVehicleDetails(vehicle);

    const text = strings.INITIAL_INTERESTED_MESSAGE.replace(
      "{vin}",
      vehicleDetails
    );
    sendTextMessage({ text });
  };

  const scheduleTestDrive = (vehicle) => {
    const vehicleDetails = getVehicleDetails(vehicle);
    const text = strings.SCHEDULE_TEST_DRIVE.replace("{vin}", vehicleDetails);
    sendTextMessage({ text });
  };

  const handleSend = () => {
    if (!text.trim()) return;
    setText("");
    sendTextMessage({
      text,
    });
  };

  const hideAppointmentBooking = () => {
    // remove BOOKING from messages
    // setMessages((prevMessages) => {
    //   return prevMessages.filter((message) => message.type !== "BOOKING");
    // });
  };

  const handleSubmitUserAppointmentBooking = async ({
    firstName,
    lastName,
    phoneNumber,
    name,
    date,
    time,
    messageId,
  }) => {
    runTurnstileReset();
    try {
      await updateContact({
        firstName: firstName || name,
        lastName,
        phoneNumber,
        email: "",
        appointmentDate: date,
        appointmentTime: time,
        customLocationId: getCustomLocationId(),
        language,
        messageId,
        setupLocationId: chatData?._location_id,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const renderUserAppointmentBooking = ({ message }) => {
    return (
      <LeadAppointmentForm
        chatData={chatData}
        submit={handleSubmitUserAppointmentBooking}
        firstAndLastNameSeparately={firstAndLastNameSeparately}
        currentUrl={currentUrl}
        message={message}
      />
    );
  };

  const SubmitForm = ({ message }) => {
    return (
      <SimpleLeadForm
        chatData={chatData}
        //submit={handleSend}
        name={name}
        setName={setName}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        firstAndLastNameSeparately={firstAndLastNameSeparately}
        firstName={firstName}
        lastName={lastName}
        setFirstName={setFirstName}
        setLastName={setLastName}
        viewIndex={viewIndex}
        img={img}
        second_message={second_message}
        organization_name={organization_name}
        color={color}
        typingThree={typingThree}
        submit={() =>
          handleSubmitLeadForm({
            messageId: message._id,
          })
        }
        selectedQuestion={selectedQuestion}
        description={uixFormDescription}
        answer={answer}
        dateQuestion={dateQuestion}
        typingTwo={typingTwo}
        message={message}
      />
    );
  };

  const renderInputFiled = () => {
    if (readOnly) return null;
    const hasFormInMessages = messages.some(
      (message) => message.type === "FORM" && !message.hidden
    );
    if (hasFormInMessages) return null;

    return (
      <MessagingInput
        submit={handleSend}
        color={color}
        arrowColor={secondaryColor}
        disabled={isSending}
        value={text}
        handleChange={(e) => setText(e.target.value)}
        token={_token}
        inputPlaceholder={
          chatData?.texts?.input_placeholder || strings.TYPE_MESSAGE
        }
      />
    );
  };

  const handleRetry = async (failedMessage) => {
    try {
      runTurnstileReset();
      await sendMessage({
        text: failedMessage.text,
        url: currentUrl,
        customLocationId: getCustomLocationId(),
        messageId: failedMessage._id,
        isRetry: true,
        language,
        setupLocationId: chatData?._location_id,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const renderHeader = () => {
    if (readOnly) return null;
    return <ChatHeader goBack={handleClearChat} />;
  };

  const renderContent = () => {
    return (
      <>
        <MessagesList
          messages={messages}
          color={color}
          isLoading={isSending}
          avatarImg={image_url}
          SubmitForm={SubmitForm}
          secondaryColor={secondaryColor}
          imInterested={imInterested}
          organizationTimeZone={organizationTimeZone}
          organizationWorkingHours={organizationWorkingHours}
          organizationCustomWorkingHours={organizationCustomWorkingHours}
          hideAppointmentBooking={hideAppointmentBooking}
          scheduleTestDrive={scheduleTestDrive}
          UserLeadForm={renderUserAppointmentBooking}
          onRetry={handleRetry}
          redirectToVdp={redirectToVdp}
        />
        {error && <ErrorAlert message={error} />}
        {renderInputFiled()}
      </>
    );
  };

  return (
    <Container>
      {renderHeader()}
      {renderContent()}
    </Container>
  );
};

export default MessagingApp;
