import React from "react";
import styled from "styled-components";
import SubmitText from "../../Buttons/CTA/SubmitText";
import { SendIcon } from "../../Icons/Icons";

const InputField = styled.input`
  width: 100%;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #efefef;
  font: normal normal normal 15px/20px Roboto;
  letter-spacing: 0px;
  color: #747b95;
  padding-right: 10px;
  height: ${(props) => (props.theme === "default" ? "50px" : "30px")};
  border-radius: ${(props) => (props.theme === "default" ? "27px" : "4px")};
  padding-left: ${(props) => (props.theme === "default" ? "21px" : "10px")};
  font-family: BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif;

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  z-index: 2;

  #name {
    font-size: ${(props) => props.fontSize} !important;
  }
`;

const SubmitButton = styled.div`
  height: 25px;
  width: 25px;
  background: ${(props) => props.color};
  border-radius: 4px;
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  filter: ${(props) => (props.disabled ? "grayscale(1);" : "none")};

  & svg {
    position: relative;
    left: 4px;
  }
`;

const TextInput = (props) => {
  const {
    theme,
    onSubmit,
    enableSubmit,
    primaryColor,
    buttonText,
    useIcon,
    fontSize = 14,
  } = props;

  let button = theme === "default" && (
    <SubmitText
      id="submit-text"
      backgroundColor={primaryColor}
      onClick={onSubmit}
      enableSubmit={enableSubmit}
      text={buttonText}
    />
  );

  if (useIcon) {
    button = (
      <SubmitButton
        color={primaryColor}
        onClick={onSubmit}
        disabled={!enableSubmit}
        id="small-submit-icon"
      >
        {SendIcon()}
      </SubmitButton>
    );
  }

  return (
    <Wrapper id="name-wrapper" fontSize={fontSize}>
      <InputField {...props} maxLength={25} />
      {button}
    </Wrapper>
  );
};

export default TextInput;
