import { useState, useEffect } from "react";
import { validatePhoneNumber } from "./validation";

export const useSimpleLeadForm = ({ chatData, chatCustomer, submit }) => {
  const {
    isCustomerNameRequired = false,
    firstAndLastNameSeparately = false,
    organization_country_code = "us",
  } = chatData || {};

  const [isFormValid, setIsFormValid] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    // Initialize fields from chatCustomer
    if (firstAndLastNameSeparately) {
      setFirstName(chatCustomer?.firstName || "");
      setLastName(chatCustomer?.lastName || "");
    } else {
      setName(chatCustomer?.firstName || "");
    }
    setPhoneNumber(chatCustomer?.phoneNumber || "");
  }, [chatCustomer, firstAndLastNameSeparately]);

  useEffect(() => {
    setIsFormValid(!mobileNumberError && phoneNumber && checkNameValidation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileNumberError, phoneNumber, firstName, lastName, name]);

  const handleNameChange = (value) => {
    setName(value);
  };

  const handleMobileNumberChange = (value) => {
    setPhoneNumber(value);
    validatePhone({ value });
  };

  const checkNameValidation = () => {
    if (!isCustomerNameRequired) return true;
    if (firstAndLastNameSeparately) {
      return firstName.trim() !== "" && lastName.trim() !== "";
    } else {
      return name.trim() !== "";
    }
  };

  const handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    const isNameValid = checkNameValidation();
    setFormSubmitted(true);
    if (!isFormValid || !isNameValid) return;

    submit({
      viewIndex: 3,
      send: true,
    });
  };

  const validatePhone = ({ value }) => {
    const text = value || phoneNumber;
    const isMobileNumberValid = text.trim() !== "" && validatePhoneNumber(text);
    setMobileNumberError(!isMobileNumberValid);
  };

  const getPhoneNumberFromClipboard = (event) => {
    let pasteValue = (event.clipboardData || window.clipboardData).getData(
      "text"
    );
    pasteValue = pasteValue.replace(/-|\s|\(|\)|tel:|\+/g, "");

    if (organization_country_code.toLowerCase() === "au") {
      if (pasteValue.length === 9) {
        pasteValue = `0${pasteValue}`;
      }
      if (pasteValue.length >= 11) {
        pasteValue = `0${pasteValue.slice(2)}`;
      }
    } else {
      if (pasteValue.length === 10) {
        pasteValue = `1${pasteValue}`;
      }
      if (pasteValue.length === 11) {
        pasteValue = `1${pasteValue.slice(1)}`;
      }
    }

    validatePhone({ value: pasteValue });
    setPhoneNumber(pasteValue);
  };

  return {
    name,
    firstName,
    lastName,
    phoneNumber,
    setName,
    setFirstName,
    setLastName,
    setPhoneNumber,
    formSubmitted,
    mobileNumberError,
    isFormValid,
    handleNameChange,
    handleMobileNumberChange,
    handleSubmit,
    getPhoneNumberFromClipboard,
    email,
    setEmail,
  };
};
