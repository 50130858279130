import { apiGet } from "../../api/apiClient";

class ChatSettingsMainActions {
  static getSingleForWidget = async (params, location_id) => {
    return await apiGet(`triggers/${location_id}`, {
      params,
    });
  };

  static getStories = async (params, token) => {
    return await apiGet(`stories/public/token/${token}`, {
      params,
    });
  };
}

export default ChatSettingsMainActions;
