import React from "react";

export const Iconset = {
  startConversation: (
    <svg width={15.356} height={14.033} viewBox="0 0 15.356 14.033">
      <path
        data-name="Path 5646"
        d="M13.316 0a2.042 2.042 0 012.04 2.04v7.07a2.042 2.042 0 01-2.04 2.04h-1.173v2.883L8.294 11.15H2.039A2.042 2.042 0 010 9.11V2.04A2.042 2.042 0 012.039 0zm1.011 9.11V2.04a1.012 1.012 0 00-1.011-1.011H2.039a1.012 1.012 0 00-1.01 1.011v7.07a1.012 1.012 0 001.01 1.011h6.6l2.477 1.856v-1.856h2.2a1.012 1.012 0 001.012-1.011zM5.363 4.8a.772.772 0 11-.772.772.772.772 0 01.772-.772zm4.63 0a.772.772 0 11-.772.772.772.772 0 01.772-.772zm-2.315 0a.772.772 0 11-.772.772.772.772 0 01.772-.772z"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  ),
  sendMessage: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clipPath="url(#clip0_15411_1017)">
        <path
          d="M16.0483 0.462828C15.6481 0.053861 15.0559 -0.0974011 14.5037 0.0626646L1.6264 3.78339C1.04376 3.94505 0.630789 4.40684 0.519544 4.99269C0.405898 5.58973 0.80286 6.34843 1.32147 6.66536L5.34792 9.12396C5.76089 9.37691 6.2939 9.31368 6.63565 8.97112L11.2464 4.36043C11.4784 4.11953 11.8626 4.11953 12.0947 4.36043C12.3267 4.59172 12.3267 4.96867 12.0947 5.20877L7.47599 9.81943C7.13345 10.162 7.06942 10.6934 7.32153 11.1072L9.78175 15.1489C10.0698 15.6283 10.5661 15.9012 11.1103 15.9012C11.1743 15.9012 11.2464 15.9012 11.3104 15.8923C11.9346 15.8131 12.4308 15.3881 12.6149 14.7879L16.4325 2.00746C16.6005 1.46324 16.4485 0.870994 16.0483 0.462828Z"
          fill="white"
        />
        <path
          opacity="0.4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.30903 11.851C1.15537 11.851 1.0017 11.7926 0.884856 11.675C0.65036 11.4405 0.65036 11.0611 0.884856 10.8266L1.9773 9.73336C2.2118 9.49969 2.59195 9.49969 2.82645 9.73336C3.06014 9.96788 3.06014 10.348 2.82645 10.5825L1.7332 11.675C1.61636 11.7926 1.4627 11.851 1.30903 11.851ZM4.31922 12.8054C4.16556 12.8054 4.0119 12.7469 3.89505 12.6293C3.66056 12.3948 3.66056 12.0154 3.89505 11.7809L4.9875 10.6876C5.222 10.454 5.60215 10.454 5.83665 10.6876C6.07034 10.9222 6.07034 11.3024 5.83665 11.5368L4.7434 12.6293C4.62655 12.7469 4.47288 12.8054 4.31922 12.8054ZM4.52225 15.6609C4.63911 15.7785 4.79277 15.837 4.94643 15.837C5.10009 15.837 5.25376 15.7785 5.3706 15.6609L6.46385 14.5685C6.69755 14.334 6.69755 13.9538 6.46385 13.7193C6.22936 13.4856 5.8492 13.4856 5.6147 13.7193L4.52225 14.8126C4.28776 15.047 4.28776 15.4264 4.52225 15.6609Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_15411_1017">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  matadorIcon: (
    <svg
      width="95"
      height="25"
      viewBox="0 0 95 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.9178 18.2438V19.9104C54.3935 20.0295 53.8687 20.089 53.3443 20.089C51.3627 20.089 50.0805 19.1366 50.0805 16.9342V12.47H48.6235V10.8033H50.0805V8.24377H52.004V10.8033H54.6266V12.47H52.004V16.7557C52.004 17.9462 52.6451 18.3033 53.7524 18.3033C54.1017 18.3628 54.5098 18.3033 54.9178 18.2438Z"
        fill="black"
      />
      <path
        d="M36.3845 7.4697V19.9102H34.4611V12.2316L31.0225 16.815H30.3814L26.9427 12.2316V19.9102H25.0194V7.4697H25.7188L30.7311 14.315L35.7434 7.4697H36.3845Z"
        fill="black"
      />
      <path
        d="M47.2244 10.8033V19.9104H45.4177V18.72C44.66 19.6128 43.5526 20.1485 42.387 20.089C39.8809 20.089 38.0158 18.0652 38.0158 15.3271C38.0158 12.589 39.8226 10.5652 42.387 10.5652C43.6109 10.5057 44.7766 11.1009 45.5342 12.0533V10.8033H47.2244ZM45.3594 15.3866C45.3594 13.3033 44.0189 12.4104 42.6784 12.4104C41.0465 12.47 39.8226 13.8985 39.9392 15.5057C39.9974 17.0533 41.2213 18.2438 42.6784 18.3033C44.0189 18.3033 45.3594 17.4104 45.3594 15.3866Z"
        fill="black"
      />
      <path
        d="M64.8846 10.8033V19.9104H63.0194V18.7199C62.2615 19.6128 61.1542 20.1485 59.9887 20.089C57.4824 20.089 55.6177 18.0652 55.6177 15.3271C55.6177 12.589 57.4243 10.5652 59.9887 10.5652C61.1542 10.5652 62.3201 11.1009 63.0775 12.1128V10.8033H64.8846ZM62.9613 15.3866C62.9613 13.3033 61.6209 12.4104 60.28 12.4104C58.6484 12.4699 57.4243 13.8985 57.5411 15.5057C57.5992 17.0533 58.8233 18.2437 60.28 18.3033C61.6209 18.3033 62.9613 17.4104 62.9613 15.3866Z"
        fill="black"
      />
      <path
        d="M64.8846 10.8033V19.9104H63.0194V18.7199C62.2615 19.6128 61.1542 20.1485 59.9887 20.089C57.4824 20.089 55.6177 18.0652 55.6177 15.3271C55.6177 12.589 57.4243 10.5652 59.9887 10.5652C61.1542 10.5652 62.3201 11.1009 63.0775 12.1128V10.8033H64.8846ZM62.9613 15.3866C62.9613 13.3033 61.6209 12.4104 60.28 12.4104C58.6484 12.4699 57.4243 13.8985 57.5411 15.5057C57.5992 17.0533 58.8233 18.2437 60.28 18.3033C61.6209 18.3033 62.9613 17.4104 62.9613 15.3866Z"
        fill="black"
      />
      <path
        d="M75.7251 7.64871V19.9106H73.9767V18.6011C73.2193 19.613 72.0533 20.1487 70.8297 20.0892C68.2653 20.0892 66.4587 18.0654 66.4587 15.3273C66.4587 12.5892 68.2653 10.5654 70.8297 10.5654C71.937 10.5059 73.0443 10.982 73.8023 11.8749V7.64871H75.7251ZM73.8604 15.3868C73.8604 13.3035 72.52 12.4106 71.1791 12.4106C69.5475 12.4702 68.3234 13.8987 68.4402 15.5059C68.4983 17.0535 69.7224 18.244 71.1791 18.3035C72.4614 18.3035 73.8604 17.4106 73.8604 15.3868Z"
        fill="black"
      />
      <path
        d="M77.2984 15.3273C77.2398 12.6487 79.3381 10.5059 81.9607 10.4463C84.5833 10.3868 86.6816 12.5297 86.7398 15.2083C86.7984 17.8868 84.7001 20.0297 82.0775 20.0892H82.0194C79.4549 20.1487 77.3566 18.0654 77.2984 15.4463C77.2984 15.4463 77.2984 15.3868 77.2984 15.3273ZM84.8169 15.3868C84.8751 13.8392 83.7091 12.4701 82.1938 12.4106C80.6785 12.3511 79.3381 13.5416 79.2799 15.0892C79.2799 15.2083 79.2799 15.2678 79.2799 15.3868C79.2218 16.9345 80.3872 18.2439 81.9026 18.3035C83.4179 18.363 84.7001 17.1725 84.7583 15.6249C84.8169 15.5059 84.8169 15.4463 84.8169 15.3868Z"
        fill="black"
      />
      <path
        d="M93.4431 10.803V12.5292H92.8601C91.4616 12.5292 90.2375 13.3625 90.2375 15.0292V19.9101H88.3141V10.803H90.0626V11.9934C90.7037 11.1601 91.636 10.6839 92.6852 10.7434C92.9769 10.6839 93.21 10.7434 93.4431 10.803Z"
        fill="black"
      />
      <path
        d="M19.6928 1.73643C19.6345 1.61738 19.4597 1.61738 19.3431 1.67691C19.2848 1.67691 19.2848 1.73643 19.2848 1.79595C19.2265 1.85548 19.2265 1.915 19.2848 1.97453C19.9843 4.11738 18.8769 6.43881 16.837 7.15308C16.429 7.27214 16.021 7.39121 15.5548 7.39121H6.87073C4.65602 7.33168 2.84927 5.48642 2.90756 3.22453C2.90756 2.80786 2.96584 2.39119 3.14068 1.97453C3.14068 1.915 3.14068 1.85548 3.14068 1.79595C3.02412 1.61738 2.90756 1.55786 2.79099 1.61738C2.73271 1.61738 2.73271 1.67691 2.67443 1.67691C2.0916 2.68881 1.74191 3.76024 1.74191 4.89119C1.74191 7.33168 3.25725 9.47454 5.53025 10.2483C3.31553 13.165 3.54866 17.3317 6.11307 19.9507L8.91064 22.8079C9.20202 23.1055 9.61 23.2841 9.95969 23.2841H12.3493C12.7573 23.2841 13.1652 23.1055 13.3984 22.8079L16.1959 19.9507C18.7603 17.3317 18.9935 13.165 16.7787 10.2483C18.9935 9.47454 20.5088 7.33168 20.5088 4.89119C20.6253 3.76024 20.3339 2.68881 19.6928 1.73643ZM14.156 17.6293L10.9505 20.9626V18.9388C9.95969 18.8793 8.96888 18.4626 8.26952 17.7483V17.6888C6.69589 16.0221 6.69589 13.3435 8.32781 11.7364C9.95969 10.1293 12.5824 10.1293 14.156 11.7959C14.9137 12.5697 15.3217 13.6412 15.3217 14.7126C15.3217 15.8435 14.9137 16.8555 14.156 17.6293Z"
        fill="#4F49F5"
      />
    </svg>
  ),
  checkIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="9.429"
      viewBox="0 0 12 9.429"
    >
      <g id="noun_Check_1807525" transform="translate(337.469 51.979)">
        <g
          id="Group_487"
          data-name="Group 487"
          transform="translate(-337.469 -51.979)"
        >
          <path
            id="Path_747"
            data-name="Path 747"
            d="M28.911,978.1l-.81.826c-1.81,1.849-3.463,3.661-5.2,5.47l-2.044-1.67-.894-.729-1.43,1.821.894.729,2.86,2.331.8.65.727-.741c2.057-2.1,3.9-4.164,5.911-6.217l.81-.826L28.911,978.1Z"
            transform="translate(-18.531 -978.097)"
            fill="#f4f7fc"
          />
        </g>
      </g>
    </svg>
  ),
  typing: (
    <svg
      style={{ margin: "auto", background: "#f1f2f3" }}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      display="block"
    >
      <circle cx={70} cy={52} r={7} fill="#5e6fa3">
        <animate
          attributeName="cy"
          calcMode="spline"
          keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
          repeatCount="indefinite"
          values="52;48;52;52"
          keyTimes="0;0.24000000000000002;0.48000000000000004;1"
          dur="1s"
          begin="-0.48000000000000004s"
        />
      </circle>
      <circle cx={50} cy={49.558} r={7} fill="#5e6fa3">
        <animate
          attributeName="cy"
          calcMode="spline"
          keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
          repeatCount="indefinite"
          values="52;48;52;52"
          keyTimes="0;0.24000000000000002;0.48000000000000004;1"
          dur="1s"
          begin="-0.32s"
        />
      </circle>
      <circle cx={30} cy={48.022} r={7} fill="#5e6fa3">
        <animate
          attributeName="cy"
          calcMode="spline"
          keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
          repeatCount="indefinite"
          values="52;48;52;52"
          keyTimes="0;0.24000000000000002;0.48000000000000004;1"
          dur="1s"
          begin="-0.16s"
        />
      </circle>
    </svg>
  ),
  noImage: (
    <svg width="15" height="15" viewBox="0 0 30 30">
      <defs>
        <clipPath id="prefix__a">
          <path fill="none" d="M0 0h30v30H0z"></path>
        </clipPath>
      </defs>
      <g data-name="Symbol 9 \u2013 1" clipPath="url(#prefix__a)">
        <path
          data-name="Union 1"
          d="M0 30v-3.75c0-4.126 6.75-7.5 15-7.5s15 3.375 15 7.5V30zM7.5 7.5A7.5 7.5 0 1115 15a7.5 7.5 0 01-7.5-7.5z"
          fill="#fff"
        ></path>
      </g>
    </svg>
  ),
  noImageDefault: (
    <svg width="15" height="15" viewBox="0 0 30 30">
      <defs>
        <clipPath id="prefix__a">
          <path fill="none" d="M0 0h30v30H0z"></path>
        </clipPath>
      </defs>
      <g data-name="Symbol 9 \u2013 1" clipPath="url(#prefix__a)">
        <path
          data-name="Union 1"
          d="M0 30v-3.75c0-4.126 6.75-7.5 15-7.5s15 3.375 15 7.5V30zM7.5 7.5A7.5 7.5 0 1115 15a7.5 7.5 0 01-7.5-7.5z"
          fill="#cdd9ea"
        ></path>
      </g>
    </svg>
  ),
};

export const SVGMessageRecived = (props) => {
  return (
    <svg
      data-name="Group 1915"
      width={11.49}
      height={5.47}
      viewBox="0 0 11.49 5.47"
    >
      <path
        data-name="Subtraction 1"
        d="M6.609 5.47L4.862 3.724l.533-.488.082-.075 1.132 1.131L10.774.126a.407.407 0 01.589 0 .408.408 0 010 .589L6.61 5.469z"
        fill={props.color ? props.color : "#a3a6be"}
      />
      <g data-name="Group 1914">
        <g data-name="Group 1913">
          <path
            data-name="Path 5627"
            d="M2.609 4.292L.715 2.4a.407.407 0 00-.589 0 .407.407 0 000 .589l2.483 2.483L7.364.717a.407.407 0 000-.589.407.407 0 00-.589 0z"
            fill={props.color ? props.color : "#a3a6be"}
          />
        </g>
      </g>
    </svg>
  );
};
export const SVGChatBack = (props) => {
  return (
    <svg width={16} height={16} viewBox="0 0 240.823 240.823">
      <path
        d="M57.633 129.007L165.93 237.268c4.752 4.74 12.451 4.74 17.215 0 4.752-4.74 4.752-12.439 0-17.179l-99.707-99.671 99.695-99.671c4.752-4.74 4.752-12.439 0-17.191-4.752-4.74-12.463-4.74-17.215 0L57.621 111.816c-4.679 4.691-4.679 12.511.012 17.191z"
        fill={props.color ? props.color : "#fff"}
      />
    </svg>
  );
};

export const SVGChatClose = (props) => {
  return (
    <svg width={12.902} height={12.44} viewBox="0 0 12.902 12.44" {...props}>
      <g data-name="Group 1972">
        <g
          data-name="Group 1859"
          fill={props.colorIcon ? props.colorIcon : "none"}
          stroke={props.color ? props.color : "#fff"}
          strokeLinecap="round"
          strokeWidth={props.stroke ? props.stroke : 1}
        >
          <path data-name="Line 303" d="M1.131 1.131l10.64 10.177" />
          <path data-name="Line 304" d="M1.131 11.308l10.64-10.177" />
        </g>
      </g>
    </svg>
  );
};

export const SVGSendIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14.468}
      height={14.466}
      viewBox="0 0 14.468 14.466"
      {...props}
    >
      <g data-name="Group 8756">
        <path
          data-name="Path 6126"
          d="M.436 0A.455.455 0 000 .47a.5.5 0 00.015.1l1.506 5.645 6.652 1.018-6.65 1.017L.015 13.9a.453.453 0 00.641.521l13.562-6.785a.45.45 0 000-.807L.656.048A.471.471 0 00.436 0z"
          fill={props.color ? props.color : "#fff"}
        />
      </g>
    </svg>
  );
};

export const SVGSuccess = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 51 51"
    >
      <g
        id="Group_8767"
        data-name="Group 8767"
        transform="translate(1758 -2878)"
      >
        <circle
          id="Ellipse_681"
          data-name="Ellipse 681"
          cx="25.5"
          cy="25.5"
          r="25.5"
          transform="translate(-1758 2878)"
          fill="#00cb85"
          opacity="0.06"
        />
        <circle
          id="Ellipse_682"
          data-name="Ellipse 682"
          cx="19.877"
          cy="19.877"
          r="19.877"
          transform="translate(-1752.378 2883.622)"
          fill="#00cb85"
          opacity="0.06"
        />
        <circle
          id="Ellipse_683"
          data-name="Ellipse 683"
          cx="14.255"
          cy="14.255"
          r="14.255"
          transform="translate(-1746.698 2889.302)"
          fill="#00cb85"
          opacity="0.16"
        />
        <g id="noun_Check_1813701" transform="translate(-1739.844 2898.44)">
          <g id="Group_7051" data-name="Group 7051" transform="translate(0 0)">
            <path
              id="Path_5801"
              data-name="Path 5801"
              d="M13.567,0a1,1,0,0,0-.689.305C10.392,2.8,7.732,5.652,5.248,8.171L1.7,5.252A1,1,0,1,0,.435,6.793L4.692,10.3a1,1,0,0,0,1.346-.063c2.692-2.7,5.613-5.858,8.264-8.514A1,1,0,0,0,13.567,0Z"
              fill="#00cb85"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const SVGSwipeIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.686"
      height="28.686"
      viewBox="0 0 28.686 28.686"
    >
      <g
        id="Send_1_"
        data-name="Send (1)"
        transform="translate(14.298) rotate(45)"
      >
        <path
          id="Path_6915"
          data-name="Path 6915"
          d="M19.772.589A1.973,1.973,0,0,0,17.808.08L1.432,4.811A1.948,1.948,0,0,0,.025,6.349a2.275,2.275,0,0,0,1.02,2.127L6.165,11.6A1.332,1.332,0,0,0,7.8,11.408l5.863-5.863a.748.748,0,0,1,1.079,0,.768.768,0,0,1,0,1.079L8.871,12.487a1.333,1.333,0,0,0-.2,1.638l3.129,5.14a2.037,2.037,0,0,0,1.944.945,1.978,1.978,0,0,0,1.659-1.4L20.261,2.553A1.977,1.977,0,0,0,19.772.589Z"
          transform="translate(0 0)"
          fill={props.color ? props.color : "#5b66ea"}
        />
        <path
          id="Path_6916"
          data-name="Path 6916"
          d="M7.318,5.292a.765.765,0,0,1,0,1.08L5.928,7.761A.763.763,0,0,1,4.849,6.682l1.389-1.39A.765.765,0,0,1,7.318,5.292Zm-.8-3.855a.765.765,0,0,1,0,1.08L5.13,3.906A.763.763,0,0,1,4.052,2.827l1.389-1.39A.765.765,0,0,1,6.521,1.436ZM2.693.223a.765.765,0,0,1,0,1.08L1.3,2.692A.763.763,0,0,1,.224,1.613L1.613.223A.765.765,0,0,1,2.693.223Z"
          transform="translate(0.265 12.154)"
          fill={props.color ? props.color : "#5b66ea"}
          opacity="0.4"
        />
      </g>
    </svg>
  );
};

export const ArrowRight = (props) => {
  return (
    <svg
      className="arrow-right"
      width="9"
      height="15"
      viewBox="0 0 9 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.81915 7.80351L0.45665 13.166L1.98848 14.6978L8.88281 7.80351L1.98848 0.90918L0.45665 2.44101L5.81915 7.80351Z"
        fill="#4A4A4A"
      />
    </svg>
  );
};

export const SendIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.247 5.77537L2.52393 11.4497C1.94578 11.6887 1.55876 12.2249 1.51385 12.8489C1.46898 13.4729 1.77528 14.059 2.31323 14.3783L6.56186 16.9004L10.7885 12.6737C10.983 12.4792 11.2984 12.4792 11.4929 12.6737C11.6874 12.8682 11.6874 13.1836 11.4929 13.3781L7.26626 17.6048L9.78832 21.8534C10.1077 22.3914 10.6937 22.6977 11.3177 22.6528C11.943 22.6078 12.4783 22.2198 12.717 21.6427L18.3913 7.91961C18.6496 7.29481 18.5131 6.60969 18.035 6.13161C17.5569 5.65352 16.8718 5.51697 16.247 5.77537Z"
      fill="white"
    />
  </svg>
);

export const SmsIcon = ({ fill = "#fff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="24"
    viewBox="0 0 24 30"
  >
    <g>
      <path
        fill={fill}
        d="M28.035 2.719H1.965A1.967 1.967 0 000 4.688V21.19c0 1.086.879 1.965 1.965 1.965h8.652l2.88 3.422c.374.445.925.703 1.503.703.582 0 1.129-.258 1.504-.703l2.879-3.422h8.652A1.964 1.964 0 0030 21.191V4.688a1.967 1.967 0 00-1.965-1.97zm0 18.472H18.47L15 25.313l-3.469-4.122H1.965V4.688h26.07zm0 0"
      ></path>
      <path
        fill={fill}
        d="M5.594 10.285h8.972a.982.982 0 100-1.965H5.594a.982.982 0 000 1.965zm0 0M4.652 15.328c0 .543.438.98.98.98h18.735a.982.982 0 000-1.965H5.633a.982.982 0 00-.98.985zm0 0"
      ></path>
    </g>
  </svg>
);

export const ModernSmsIcon = ({ fill = "#fff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill={fill}
  >
    <path d="M3.6 4.9C2.1 6.5 2 7.2 3 9.2c2.6 4.9 6.3 8.9 10.1 10.9 3.8 1.9 4.2 1.9 6.5.4 3-1.9 3-2.6-.1-5.5-2-1.9-2.7-2.1-4-1-2.3 1.9-5.8-1.5-5-4.8.4-1.6 0-3.1-1.1-4.3-2.2-2.4-3.6-2.4-5.8 0zm4.9 3.8c-.8 3.3 5 9 7.2 7.2 1.1-1 1.7-.9 3 .3 1.8 1.9 1 3.8-1.6 3.8-2.8 0-9.4-5.3-11.5-9.2-1.7-3.1-1.8-3.9-.7-5.2 1.9-2.3 4.4-.1 3.6 3.1z" />
  </svg>
);

export const MessageIconNew = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M13.5 3H11.5C6.52944 3 2.5 7.02944 2.5 12V17C2.5 19.2091 4.29086 21 6.5 21H13.5C18.4706 21 22.5 16.9706 22.5 12C22.5 7.02944 18.4706 3 13.5 3Z"
      fill="white"
    />
  </svg>
);
