import Cookies from "js-cookie";

export const getCachedData = (key) => {
  try {
    const localData = localStorage.getItem(key);

    if (localData) {
      const parsedItem = JSON.parse(localData);

      if (parsedItem.expiry && parsedItem.expiry < Date.now()) {
        localStorage.removeItem(key);
        return null;
      }

      return parsedItem.data;
    }

    const cachedItem = Cookies.get(key);
    if (!cachedItem) return null;

    const parsedItem = JSON.parse(cachedItem);
    if (parsedItem.expiry && parsedItem.expiry < Date.now()) {
      Cookies.remove(key);
      return null;
    }

    return parsedItem.data;
  } catch (error) {
    localStorage.removeItem(key);
    Cookies.remove(key);
    return null;
  }
};

export const setCacheData = (key, data, expirationInSeconds) => {
  const expiry = Date.now() + expirationInSeconds * 1000;
  const valueToStore = JSON.stringify({ data, expiry });

  try {
    localStorage.setItem(key, valueToStore);
  } catch (e) {
    if (valueToStore.length < 3500) {
      Cookies.set(key, valueToStore, { path: "/" });
    }
  }
};
