import React from "react";
import "../styles/LiveChatStyle.css";
import { SendIcon, ArrowRight } from "./Icons/Icons";
import PhoneNumberField from "./Fields/PhoneNumberField";
import MetaButton from "./Buttons/SubmitButton";
import { strings } from "../utils/localization";
import SuccessMessage from "./Chat/SuccessMessage";
import ChatHeader from "./Chat/ChatHeader";
import ChatContent from "./Chat/ChatContent";
import styled from "styled-components";
import DefaultFooter from "./Footer/DefaultFooter";

const Input = styled.input`
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 13px;
  width: 100%;
  height: 46px;
  resize: none;
  outline: none;
`;

const SubmitButton = styled.div`
  height: 34px;
  width: 34px;
  background: ${(props) => props.color};
  border-radius: 9px;
  position: absolute;
  top: 6px;
  right: 10px;
  cursor: pointer;

  & svg {
    position: relative;
    top: 6px;
    left: 7px;
  }
`;

const Container = styled.div`
  background: #ffffff;
  box-shadow: 2px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 16px;
`;

const Subtitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin-top: 0;
  margin-bottom: 16px;
`;

const QuestionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 13px 13px 13px 13px;
  padding-left: 13px;
  margin-bottom: 12px;
  cursor: pointer;

  & .arrow-right {
    padding-right: 6px;
  }
`;

const QuestionLabel = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  opacity: 0.9;
  margin: 0;
`;

const ChatBoxPreview = (props) => {
  const renderChatBoxOne = () => {
    const { chatData, setQuestionId, handleSelectQuestion, listQuestions } =
      props;

    let formattedLanguage = strings.getLanguage();

    if (formattedLanguage === "es") {
      formattedLanguage = "sp";
    }

    return (
      <div className="matadot-live-chat-123789-chatbox-middle">
        <div className="matadot-live-chat-123789-questons-scrollbars">
          <Container style={{ paddingBottom: 0 }}>
            <Subtitle>{strings.TEXT_CHAT_HELP_CHOOSE_QUESTION}</Subtitle>
            {listQuestions &&
              listQuestions.map((obj, index) => {
                let question =
                  obj._question &&
                  obj._question.question &&
                  obj._question.question[formattedLanguage]
                    ? obj._question.question[formattedLanguage]
                    : obj._question.question[
                        Object.keys(obj._question.question)[0]
                      ]
                    ? obj._question.question[
                        Object.keys(obj._question.question)[0]
                      ]
                    : "";
                return (
                  <QuestionBox
                    key={index}
                    onClick={() => {
                      setQuestionId(obj._question_id);
                      handleSelectQuestion(question);
                    }}
                  >
                    <QuestionLabel>{question}</QuestionLabel>
                    {ArrowRight()}
                  </QuestionBox>
                );
              })}
          </Container>
          <Container>
            <Subtitle>{strings.FIND_YOUR_ANSWER_NOW}</Subtitle>
            <div style={{ display: "flex", position: "relative" }}>
              <Input
                placeholder={strings.TEXT_ENTER_YOUR_QUESTION_HERE}
                value={selectedQuestion}
                onChange={(e) => {
                  setSelectedQuestion(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (selectedQuestion) {
                      handleSelectQuestion(selectedQuestion);
                    }
                  }
                }}
              />
              <SubmitButton
                color={chatData.color}
                onClick={() => {
                  if (selectedQuestion) {
                    handleSelectQuestion(selectedQuestion);
                  }
                }}
              >
                {SendIcon()}
              </SubmitButton>
            </div>
          </Container>
        </div>
      </div>
    );
  };

  const renderChatBoxTwoAndTree = () => {
    let {
      chatData,
      selectedQuestion,
      dateQuestion,
      phoneNumber,
      typingTwo,
      submit,
      viewIndex,
      typingThree,
      setPhoneNumber,
      setMessagesEndRef,
      defaultCountryFlag,
      isValidPhoneNumber,
      getPhoneNumberFromClipboard,
      scrollToBottom,
    } = props;
    let location_name =
      chatData && chatData.location_name ? chatData.location_name : "";
    let organization_name =
      chatData && chatData.organization_name ? chatData.organization_name : "";
    let backgroundColor = chatData && chatData.color ? chatData.color : "red";
    let first_message =
      chatData && chatData.texts.first_message
        ? chatData.texts.first_message
        : "";
    let second_message =
      chatData && chatData.texts.second_message
        ? chatData.texts.second_message
        : "";
    let color = chatData && chatData.color ? chatData.color : "white";
    let img = chatData && chatData.image_url ? chatData.image_url : "";

    return (
      <>
        <div
          className={
            "matadot-live-chat-123789-chatbox-middle " +
            ((viewIndex === 2 &&
              "matadot-live-chat-123789-chatbox-middle-two") ||
              (viewIndex === 3 &&
                "matadot-live-chat-123789-chatbox-middle-three"))
          }
          style={{
            height: viewIndex === 2 || viewIndex === 3 ? "100%" : "auto",
            paddingTop: viewIndex === 2 || viewIndex === 3 ? "0px" : "5px",
          }}
        >
          <div
            ref={(el) => {
              setMessagesEndRef(el);
            }}
            className="matadot-live-chat-123789-questons-scrollbars"
            style={{
              paddingTop: viewIndex === 2 || viewIndex === 3 ? "20px" : "0px",
            }}
          >
            <ChatContent
              backgroundColor={backgroundColor}
              phoneNumber={phoneNumber}
              dateQuestion={dateQuestion}
              typingTwo={typingTwo}
              selectedQuestion={selectedQuestion}
              img={img}
              location_name={location_name}
              first_message={first_message}
              viewIndex={viewIndex}
              color={color}
              scrollToBottom={scrollToBottom}
            />
            {viewIndex === 3 && (
              <SuccessMessage
                typingThree={typingThree}
                img={img}
                organization_name={organization_name}
                color={color}
                second_message={second_message}
                backgroundColor={backgroundColor}
              />
            )}
          </div>
        </div>
        {viewIndex !== 3 && (
          <div className="matadot-live-chat-123789-write-text-message">
            <PhoneNumberField
              value={phoneNumber}
              onChange={(value) => setPhoneNumber(value)}
              defaultCountryFlag={defaultCountryFlag}
              getPhoneNumberFromClipboard={getPhoneNumberFromClipboard}
            />
            <MetaButton
              style={{ backgroundColor: color }}
              onClick={() => submit({ viewIndex: 3, send: true })}
              disabled={!isValidPhoneNumber}
            />
          </div>
        )}
      </>
    );
  };

  // const renderFooter = () => {
  //     const { viewIndex, chatData } = props;

  //     return <Footer
  //         lang={strings.getLanguage()}
  //         viewIndex={viewIndex}
  //         termsLink={chatData.termsLink}
  //         color={chatData.color}
  //     />
  // }

  const getMainContent = (viewIndex) => {
    if (viewIndex === 1) {
      return renderChatBoxOne();
    } else if (viewIndex === 2 || viewIndex === 3) {
      return renderChatBoxTwoAndTree();
    }
  };

  const {
    viewIndex,
    setSelectedQuestion,
    onBackClick,
    chatData,
    selectedQuestion,
    backView,
    handleSelectQuestion,
  } = props;
  if (!chatData) {
    return null;
  }

  const welcome_title =
    chatData && chatData.texts ? chatData.texts.welcome_title : "";
  const backgroundColor = chatData && chatData.color ? chatData.color : "red";
  const mainContent = getMainContent(viewIndex);

  return (
    <div
      className="matadot-live-chat-123789-chatbox-preview"
      style={{ borderRadius: 12, backgroundColor: "#fff" }}
    >
      <div
        className={
          `matadot-live-chat-123789-chatbox matadot-live-chat-123789-chatbox-current-view-${viewIndex}` +
          (backView && backView ? backView : "")
        }
        style={{ height: chatData.height }}
      >
        <ChatHeader
          viewIndex={viewIndex}
          backgroundColor={backgroundColor}
          onBackClick={onBackClick}
          setSelectedQuestion={setSelectedQuestion}
          selectedQuestion={selectedQuestion}
          welcome_title={welcome_title}
          handleSelectQuestion={handleSelectQuestion}
        />
        {mainContent}
        {
          <DefaultFooter
            backgroundColor={viewIndex === 1 ? "#f6f7fb" : "#fff"}
            viewIndex={viewIndex}
          />
        }
      </div>
    </div>
  );
};

export default ChatBoxPreview;
