import { apiGet } from "../../api/apiClient";

const urlPopup = "/popups/location";

class PopupMainActions {
  static getPopup = async (locationId) => {
    return await apiGet(`${urlPopup}/${locationId}`);
  };
}

export default PopupMainActions;
