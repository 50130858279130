import React, { useEffect } from "react";
import styled from "styled-components";
import AcceptTermsFooter from "../../Footer/AcceptTermsFooter";
import { strings } from "../../../../utils/localization";
import "react-date-picker/dist/DatePicker.css";
import "react-time-picker/dist/TimePicker.css";
import "../../../../styles/LiveChatStyle.css";
import ModernPhoneNumberField from "../../../../components/Fields/ModernPhoneNumberField";
import ModernInput from "../../Input/ModernInput";
import ModerSubmitButton from "../../../../components/Buttons/ModerSubmitButton";
import { useChatContext } from "../../../contexts/ChatContext";
import { useSimpleLeadForm } from "../../../../utils/useSimpleLeadForm";

const Container = styled.form`
  max-width: 400px;
  margin: 0 auto;

  .matadot-live-chat-123789-main-input {
    width: 100%;
    height: 46px;
    border-radius: 5px !important;
  }

  .flag-dropdown {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;

const Note = styled.p`
  margin-top: -5px;
  margin-bottom: 5px;
  font-size: 12px;
  color: #c43423;
  margin-bottom: 2px;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Form = styled.div`
  border-radius: 14px 14px 0px 14px;
  margin-bottom: 10px;

  .matadot-live-chat-123789-main-input {
    background-color: #f9f9f9;
    border-bottom-width: 1.3px;
    border-top: 0px;
    border-right: 0px;
    border-radius: 0px;
    color: #17181b;
  }

  .matadot-live-chat-123789-main-input:focus {
    border-bottom-color: ${({ primaryColor }) => primaryColor};
  }
`;

const RightContent = styled.div`
  background-color: #fafbfd;
  padding: 12px;
  border-radius: 16px;
  max-width: 90%;
`;

const SimpleLeadForm = ({
  chatData,
  submit,
  name: propName,
  setName: setPropName,
  phoneNumber: propPhoneNumber,
  setPhoneNumber: setPropPhoneNumber,
  firstAndLastNameSeparately,
  firstName: propFirstName,
  lastName: propLastName,
  setFirstName: setPropFirstName,
  setLastName: setPropLastName,
  currentUrl,
  message,
}) => {
  const { chatCustomer, readOnly } = useChatContext();
  const { isSubmitted } = message;
  let backgroundColor = chatData && chatData.color ? chatData.color : "red";

  const scrollToBottom = () => {
    const el = document.getElementById("matador-connect-main-content");
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  };

  const {
    name,
    firstName,
    lastName,
    phoneNumber,
    formSubmitted,
    mobileNumberError,
    isFormValid,
    handleNameChange,
    handleMobileNumberChange,
    handleSubmit,
    getPhoneNumberFromClipboard,
    setFirstName,
    setLastName,
  } = useSimpleLeadForm({
    chatData,
    chatCustomer,
    submit,
    scrollToBottom,
    message,
  });

  useEffect(() => {
    if (setPropName) setPropName(name);
  }, [name, setPropName]);

  useEffect(() => {
    if (setPropFirstName) setPropFirstName(firstName);
  }, [firstName, setPropFirstName]);

  useEffect(() => {
    if (setPropLastName) setPropLastName(lastName);
  }, [lastName, setPropLastName]);

  useEffect(() => {
    if (setPropPhoneNumber) setPropPhoneNumber(phoneNumber);
  }, [phoneNumber, setPropPhoneNumber]);

  const renderName = () => {
    if (firstAndLastNameSeparately) {
      return (
        <>
          <ModernInput
            placeholder={strings.FIRST_NAME}
            color={backgroundColor}
            value={firstName}
            backgroundColor="#fff"
            onChange={(value) => setFirstName(value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e);
              }
            }}
            onFocus={scrollToBottom}
            required={chatData?.isCustomerNameRequired}
            enableValidation={chatData?.isCustomerNameRequired}
            formSubmitted={formSubmitted}
            borderDisabled
            marginBottom={12}
          />
          <ModernInput
            placeholder={strings.LAST_NAME}
            color={backgroundColor}
            value={lastName}
            backgroundColor="#fff"
            onChange={(value) => setLastName(value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e);
              }
            }}
            onFocus={scrollToBottom}
            required={chatData?.isCustomerNameRequired}
            enableValidation={chatData?.isCustomerNameRequired}
            formSubmitted={formSubmitted}
            borderDisabled
          />
          <div style={{ height: 8 }}></div>
        </>
      );
    }

    return (
      <>
        <ModernInput
          placeholder={strings.FULL_NAME}
          color={backgroundColor}
          value={name}
          backgroundColor="#fff"
          onChange={handleNameChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit(e);
            }
          }}
          onFocus={scrollToBottom}
          required={chatData?.isCustomerNameRequired}
          enableValidation={chatData?.isCustomerNameRequired}
          formSubmitted={formSubmitted}
          borderDisabled
          marginBottom={12}
        />
      </>
    );
  };

  const renderSubmit = () => {
    if (readOnly) return null;

    return (
      <>
        <AcceptTermsFooter
          color={chatData?.termsLink}
          termsLink={chatData?.termsLink}
          margin={0}
          locationId={chatData?._location_id}
          currentUrl={currentUrl}
        />
        <ModerSubmitButton
          disabled={!isFormValid || formSubmitted || isSubmitted}
          marginTop={18}
          color={chatData?.color}
          secondaryColor={chatData?.secondaryColor}
        />
      </>
    );
  };

  const renderContent = () => {
    return (
      <FormContainer>
        <RightContent>
          <Form primaryColor={chatData?.color}>
            {renderName()}

            <ModernPhoneNumberField
              value={phoneNumber}
              onChange={(value) => handleMobileNumberChange(value)}
              defaultCountryFlag={
                chatData?.organization_country_code?.toLowerCase() || "us"
              }
              getPhoneNumberFromClipboard={getPhoneNumberFromClipboard}
              token={chatData?._token}
            />

            {formSubmitted && mobileNumberError && (
              <Note style={{ marginTop: 3, marginBottom: 5 }}>
                {strings.REQUIRED_FIELD}
              </Note>
            )}
          </Form>
          {renderSubmit()}
        </RightContent>
      </FormContainer>
    );
  };

  return <Container onSubmit={handleSubmit}>{renderContent()}</Container>;
};

export default SimpleLeadForm;
