import { apiPost } from "./apiClient";

class QuestionsApi {
  static sendQuestion = ({ itemId, param }) => {
    return apiPost(`triggers/${itemId}`, param);
  };

  static textMe = ({ locationId, param }) => {
    return apiPost(`text-us/${locationId}/message/create`, param);
  };

  static exitIntent = ({ locationId, param }) => {
    return apiPost(`smart-promotions/${locationId}/message/create`, param);
  };

  static desktopCoupon = ({ locationId, param }) => {
    return apiPost(`coupons/${locationId}/message/create`, param);
  };

  static subscriberGrowth = ({ locationId, param }) => {
    return apiPost(`subscriber-growth/${locationId}/message/create`, param);
  };
}

export default QuestionsApi;
