import React from "react";

import styled from "styled-components";

const Wrapper = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #00000029;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 20px 13px;
`;

const Text = styled.p`
  color: #2d2d66;
  font-family: "Roboto";
  margin: 0;
  font-size: 14px;
`;

const Note = ({ text }) => {
  return (
    <Wrapper>
      <Text>{text}</Text>
    </Wrapper>
  );
};

export default Note;
