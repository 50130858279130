import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { useEffect } from "react";
import ChatContent from "../ChatContent";
import chatSettingsMainActions from "../../../../components/modules/chatSettingsMainActions";
import PageLoader from "../../../../components/PageLoader";
import MatadorFooter from "../../Footer/DefaultFooter";
import useWiz from "./useWiz";
import useAnalytics from "../../../../utils/useAnalytics";
import { getFontFamily } from "../../../utils/getFontFamily";
import { useContextData } from "../../../contexts/ContextDataProvider";
import "../../../../styles/fonts/brand-fonts.css";
import { useParams } from "react-router-dom";
import TurnstileWidget from "../../Turnstile/TurnstileWidget";

const Container = styled.div`
  border-radius: 12px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-family: ${(props) =>
    props.fontFamily || "Arial, 'Helvetica Neue', sans-serif"} !important;
`;

const MainContent = styled.div`
  height: 100%;
  width: 100%;
  background-color: #fff;
  overflow-y: auto;

  ::-webkit-scrollbar {
    position: relative;
    display: block;
    width: 5px;
    cursor: pointer;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    margin-left: -10px;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: #00000033;
  }

  @supports (scrollbar-color: #00000033 transparent) {
    scrollbar-color: #00000033 transparent;
    scrollbar-width: thin;
  }
`;

const LoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabItem = styled.div`
  display: ${(props) => props.display};
  height: 100%;
  visibility: ${(props) => props.visible || 1};
`;

const TabContainer = styled.div`
  height: 100%;
`;

const WizLayout = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const { chatData, setChatData } = useContextData();
  const [triggers, setTriggers] = useState();
  const [clearValues, setClearValues] = useState(0);
  const { history } = props;
  const [language, setLanguage] = useState("en");
  const [isClientUIX, setIsClientUIX] = useState();
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [questionPicked, setQuestionPicked] = useState("");
  const [customActionData, setCustomActionData] = useState("");
  const {
    setWizData,
    finalView,
    activeLocation,
    setActiveLocation,
    activeToken,
    initialStepType,
  } = useWiz();
  const [questionStatus, setQuestionStatus] = useState("");
  const { sendCommEngagementEvent, sendCommSubmissionEvent, d2cUtmTracking } =
    useAnalytics(chatData?.GATrackingNumber);
  const { token, lang } = useParams();
  const [turnstileStatus, setTurnstileStatus] = useState(false);
  const turnstileRef = useRef(null);

  useEffect(() => {
    if (chatData) {
      sendCommEngagementEvent({ widget: "Connect", status: "load" });
    }
  }, [chatData]);

  useEffect(() => {
    setLanguageValue();
    loadData();
  }, []);

  const handleMatadorCustomAction = (data) => {
    setCustomActionData(data);
  };

  useEffect(() => {
    if (activeLocation) {
      setTriggers(activeLocation.triggers);
    }
  }, [activeLocation, activeLocation?.triggers]);

  const onMessageReceivedFromIframe = useCallback(
    ({ data }) => {
      if (data.type === "MATADOR-FLOW-DESIGNER-UPDATE") {
        setWizData({
          steps: data.steps,
        });
      } else {
        if (data.id) {
          // preview update
          return setChatData({
            ...chatData,
            ...data,
          });
        }

        if (data.type === "MATADOR-CUSTOM-ACTION") {
          return handleMatadorCustomAction(data);
        }

        if (data.type === "MATADOR-CLEAR-CONNECT") {
          clear();
        }
      }
    },
    [chatData]
  );

  useEffect(() => {
    window.addEventListener("message", onMessageReceivedFromIframe);
    return () =>
      window.removeEventListener("message", onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);

  const setLanguageValue = () => {
    let languageValue = lang || "en";
    if (languageValue === "sp") {
      languageValue = "es";
    }
    setLanguage(languageValue);
  };

  const getToken = () => {
    return token || 0;
  };

  const loadData = async () => {
    const token = getToken();

    let result = await chatSettingsMainActions.getSingleForWidget(
      { language: lang || "en" },
      token
    );

    if (result.success) {
      const { triggers } = result.data;
      setTriggers(triggers);
      setChatData(result.data.chatData);
      setWizData(result.data.chatDesigner);
    } else {
      history.push({
        pathname: "/error",
        state: { status: "400 " },
      });
    }
  };

  const clear = () => {
    setActiveLocation(null);
    goBack(false);
    setClearValues((prev) => prev + 1);
  };

  const changeTab = (index) => {
    if (index === 0) {
      clear();
    }
    setActiveTab(index);
  };

  const submissionGAevent = () => {
    sendCommSubmissionEvent({
      widget: "Connect",
      itemCategory: questionStatus,
    });
    d2cUtmTracking(chatData?._location_id);
  };

  const runTurnstileReset = () => {
    setTimeout(() => {
      if (turnstileRef.current) {
        turnstileRef.current?.reset();
      }
    }, 500);
  };

  const renderTabContent = () => {
    return (
      <TabContainer>
        <TabItem display={activeTab === 0 ? "block" : "none"}>
          <ChatContent
            {...props}
            chatData={chatData}
            triggers={triggers}
            clearValues={clearValues}
            isClientUIX={isClientUIX}
            setIsClientUIX={setIsClientUIX}
            selectedOrganization={selectedOrganization}
            setSelectedOrganization={setSelectedOrganization}
            questionPicked={questionPicked}
            setQuestionPicked={setQuestionPicked}
            customActionData={customActionData}
            setCustomActionData={setCustomActionData}
            submissionGAevent={submissionGAevent}
            isCustomSubmitForm={finalView === "Form"}
            setIsCustomSubmitForm={() => {}}
            isWiz
            activeLocation={activeLocation}
            language={language}
            activeToken={activeToken}
            initialStepType={initialStepType}
            setActiveLocation={setActiveLocation}
            setQuestionStatus={setQuestionStatus}
            runTurnstileReset={runTurnstileReset}
            turnstileStatus={turnstileStatus}
          />
        </TabItem>
      </TabContainer>
    );
  };

  const goBack = (change = true) => {
    setQuestionPicked("");
    setQuestionStatus("");
    setSelectedOrganization(null);
    if (change) {
      changeTab(0);
    }
  };

  const Content = chatData ? (
    <MainContent>{renderTabContent()}</MainContent>
  ) : (
    <LoaderContainer>
      <PageLoader />
    </LoaderContainer>
  );

  const renderTurnstileWidget = () => {
    if (!chatData?.aiChatEnabled) return null;
    return (
      <TurnstileWidget
        turnstileStatus={turnstileStatus}
        setTurnstileStatus={setTurnstileStatus}
        ref={turnstileRef}
      />
    );
  };

  return (
    <>
      <Container fontFamily={getFontFamily(chatData)}>
        {renderTurnstileWidget()}
        {Content}
        <MatadorFooter chatData={chatData} />
      </Container>
    </>
  );
};

export default WizLayout;
